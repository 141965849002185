import React from "react";
import { PageContext } from "../store/context/page-context";
import { FONTS_STYLES } from "../helpers/constants";
import { AVAILABLE_TARIFFS } from "../components/iframe/pro-ai-paywall-template/index.content";
import LayoutIframe from "../layouts/LayoutIframe";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import useParamsForIframePaywall from "../hooks/useParamsForIframePaywall";
import { checkParamsPrefix, getTrueClasses } from "../helpers";
import TrackedLink from "../components/Analytics/TrackedLink";
import Tariff from "../components/iframe/pro-ai-paywall-template/Tariff";
import "../components/iframe/pro-ai-paywall-template/index.scss";
import "../components/iframe/pro-ai-paywall-template/local.scss";

const ProAiPaywallTemplate = ({ pageContext }) => {
  const { i18n } = pageContext;
  const { t } = useTranslation();
  const { addProps, isRendered, paramsLink } = useParamsForIframePaywall();
  const language = i18n.language;
  const eventCategory = `${addProps.software ? addProps.software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return language !== "en" ? `/${language}${link}` : link;
  };

  return (
    <PageContext.Provider
      value={{
        translate: t,
        eventCategory,
      }}
    >
      {isRendered ? (
        <LayoutIframe
          withTagManager={false}
          className={getTrueClasses(
            "TeIPAP",
            FONTS_STYLES[language],
            language !== "en" && `TeIPAP_${language}`
          )}
          turnOnEventView
        >
          <h2 className="TeIPAP__title">
            {t("Simple and Transparent Pricing")}
          </h2>
          <h3 className="TeIPAP__subTitle">{t("AMZScout PRO AI Extension")}</h3>
          <p className="TeIPAP__text TeIPAP__text_first">
            {t(
              "Find profitable products, estimate income, and source proven suppliers based on solid sales statistics and AI features."
            )}
          </p>
          <p className="TeIPAP__text">
            {t("Perfect for both beginner and experienced Amazon sellers.")}
          </p>
          <div className="TeIPAP-offers">
            {AVAILABLE_TARIFFS.map(({ buyNowPath, ...data }) => {
              const path = updateLink(buyNowPath);
              return <Tariff key={data.tariffName} {...data} path={path} />;
            })}
          </div>
          <p className="TeIPAP__refund">{t("10-day money-back guarantee.")}</p>
          <p className="TeIPAP-support">
            {t("text before support")}
            <TrackedLink
              path="mailto:support@amzscout.net"
              category={eventCategory}
              action="Contact Us"
              classes="TeIPAP-support__link"
            >
              support@amzscout.net
            </TrackedLink>
            {t("text after support")}
          </p>
        </LayoutIframe>
      ) : null}
    </PageContext.Provider>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "pro-ai-paywall" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProAiPaywallTemplate;
