import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../../store/context/page-context";
import { getTrueClasses } from "../../../../helpers";
import { ExtensionController } from "../../../../controllers/common/extensionController";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";


const Tariff = ({ tariffName, price, cents, period, path }) => {
  const { translate: t, eventCategory } = useContext(PageContext);
  return (
    <div
      className={getTrueClasses(
        "TeIPAP-T",
        `TeIPAP-T_${tariffName.toLowerCase()}`
      )}
    >
      <p className="TeIPAP-T__name">{t(tariffName)}</p>
      <p className="TeIPAP-T-price">
        {`$${price.toLocaleString(t("language code"))}`}
        {cents && <sup className="TeIPAP-T-price__cents">{cents}</sup>}
        <span className="TeIPAP-T-price__period">{`/${t(period)}`}</span>
      </p>
      <TrackedLink
        handleClick={() =>
          ExtensionController.sendMessage({ event: "p.click" })
        }
        classes="TeIPAP-T__buyNow"
        category={eventCategory}
        action="Buy now"
        isChild
        path={path}
      >
        {t("Buy now")}
      </TrackedLink>
    </div>
  );
};
Tariff.propTypes = {
  tariffName: PropTypes.string,
  price: PropTypes.number,
  cents: PropTypes.number,
  period: PropTypes.string,
  path: PropTypes.string,
};

export default Tariff;
