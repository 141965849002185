const TARIFFS = {
  monthly: {
    tariffName: "MONTHLY",
    price: 45,
    cents: 99,
    period: "month",
    buyNowPath: "/checkout/?offerId=ai-lite-monthly",
  },
  yearly: {
    tariffName: "YEARLY",
    price: 269,
    cents: 99,
    period: "year",
    buyNowPath: "/checkout/?offerId=ai-lite-annual",
  },
  lifetime: {
    tariffName: "LIFETIME",
    price: 799,
    cents: 99,
    period: "lifetime",
    buyNowPath: "/checkout/?offerId=ai-lite-lifetime",
  },
};

export const AVAILABLE_TARIFFS = [TARIFFS.monthly, TARIFFS.yearly, TARIFFS.lifetime];
